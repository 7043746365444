import { CreditExchange } from 'api/Credit/types';
import useAddCreditExchange from 'components/Dashboard/Billing/CreditExchanges/AddCreditExchangeForm/useAddCreditExchange';

import React from 'react';
import { Button, Form, InputNumber, Space } from 'antd';

interface FormValues {
  price: number;
  rate: number;
}

interface Props {
  orgId: string;
  onCancel: () => void;
  onCreditExchangeAdded: (creditExchange: CreditExchange) => void;
}

const AddCreditExchangeForm: React.FC<Props> = ({ orgId, onCancel, onCreditExchangeAdded }) => {
  const [ form ] = Form.useForm<FormValues>();
  const { isCreating, addCreditExchange } = useAddCreditExchange(onCreditExchangeAdded);

  const onFinish = (values: FormValues) => addCreditExchange(orgId, values.price, values.rate);

  return (
    <Form
      form={form}
      name="addCreditExchange"
      style={{ width: 360 }}
      layout="vertical"
      onFinish={onFinish}
      disabled={isCreating}
    >
      <Form.Item name="price" label="Tariff price" rules={[ { required: true, message: 'Field is mandatory' } ]}>
        <InputNumber placeholder="Enter tariff price" style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item name="rate" label="Rate" rules={[ { required: true, message: 'Field is mandatory' } ]}>
        <InputNumber placeholder="Enter rate" style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
        <Space>
          <Button onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Add exchange
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default AddCreditExchangeForm;
