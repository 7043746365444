import { CreditExchange } from 'api/Credit/types';
import useUpdateCreditExchange from 'components/Dashboard/Billing/CreditExchanges/UpdateCreditExchangeForm/useUpdateCreditExchange';

import React from 'react';
import { Button, Form, InputNumber, Space } from 'antd';

interface FormValues {
  price: number;
  rate: number;
}

interface Props {
  creditExchange: CreditExchange;
  onCancel: () => void;
  onCreditExchangeUpdated: (creditExchange: CreditExchange) => void;
}

const UpdateCreditExchangeForm: React.FC<Props> = ({ creditExchange, onCancel, onCreditExchangeUpdated }) => {
  const [ form ] = Form.useForm<FormValues>();
  const { isUpdating, updateCreditExchange } = useUpdateCreditExchange(onCreditExchangeUpdated);

  const onFinish = (values: FormValues) => updateCreditExchange(creditExchange.id, values.price, values.rate);

  return (
    <Form
      form={form}
      name="updateCreditExchange"
      style={{ width: 360 }}
      layout="vertical"
      onFinish={onFinish}
      disabled={isUpdating}
      initialValues={{ rate: creditExchange.rate, price: creditExchange.creditTariff.price }}
    >
      <Form.Item name="price" label="Tariff price" rules={[ { required: true, message: 'Field is mandatory' } ]}>
        <InputNumber placeholder="Enter tariff price" style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item name="rate" label="Rate" rules={[ { required: true, message: 'Field is mandatory' } ]}>
        <InputNumber placeholder="Enter rate" style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
        <Space>
          <Button onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Update exchange
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default UpdateCreditExchangeForm;
